import { signalStore } from '@ngrx/signals';
import {
  withAutoToggle,
  withBuildInformation,
  withDarkMode,
  withRouterTracing,
  withLuxonTimezone,
} from './features';
import { withOptionalRemembering } from '@cca-common/core';

const DEV_STORAGE_KEY = 'DEV-SETTINGS';

export const DevSettingStore = signalStore(
  { providedIn: 'root' },
  withBuildInformation(),
  withDarkMode(),
  withLuxonTimezone(),
  withRouterTracing(),
  withOptionalRemembering(DEV_STORAGE_KEY),
  withAutoToggle(),
);
