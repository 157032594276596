import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import {
  signalStoreFeature,
  withMethods,
  withState,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, tap } from 'rxjs';

export type DarkModeState = { darkMode: boolean };

const darkModeStyleId = `experimental-darkmode`;
// Function to dynamically create the .experimental-dark class and insert it into the provided element
function addExperimentalDarkModeStyling(element: HTMLElement) {
  if (!element.querySelector(`#${darkModeStyleId}`)) {
    // Create a <style> element
    const style = document.createElement('style');
    style.innerHTML = `
      .experimental-dark {
        filter: invert(0.95) hue-rotate(180deg);
      }
      .experimental-dark img {
        filter: invert(100%) hue-rotate(0deg); /* Reset the filter for images */
      }
    `;
    style.id = darkModeStyleId;

    // Append the <style> element to the <head> of the document
    element.appendChild(style);
  }
}

/**
 * Using a unused generic input `_` this is to solve a known typescript error:
 * Combining multiple custom features with static input may cause unexpected compilation errors
 * This issue arises specifically with custom features that accept input but do not define any generic parameters.
 * To prevent this issue, it is recommended to specify an unused generic for such custom features:
 *
 * URL: https://ngrx.io/guide/signals/signal-store/custom-store-features
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function withDarkMode<_>() {
  return signalStoreFeature(
    withState<DarkModeState>({ darkMode: false }),

    withMethods((store, document = inject(DOCUMENT)) => ({
      updateDarkMode(darkMode: boolean): void {
        patchState(store, () => ({ darkMode: darkMode }));
      },
      _applyDarkMode: rxMethod<boolean>(
        pipe(
          tap((enabled) => {
            const htmlElement = document.body.parentElement;
            if (!htmlElement) {
              return;
            }

            if (enabled) {
              addExperimentalDarkModeStyling(document.head);
              htmlElement.classList.add('experimental-dark');
            } else {
              htmlElement.classList.remove('experimental-dark');
            }
          }),
        ),
      ),
    })),

    withHooks({
      onInit(store) {
        store._applyDarkMode(store.darkMode);
      },
    }),
  );
}
