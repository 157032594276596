<h2>Settings</h2>
<table class="p-4">
  <tr>
    <td>Remember settings</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.remember()"
        (change)="settings.updateRemember($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Dark mode</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.darkMode()"
        (change)="settings.updateDarkMode($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Default timezone</td>
    <td>
      <mat-form-field class="example-full-width">
        <mat-label>Timezone</mat-label>
        <input
          #input
          type="text"
          placeholder="Pick one"
          matInput
          [matAutocomplete]="auto"
          [formControl]="timezoneControl"
          (input)="filter(input.value)"
          (focus)="filter(input.value)"
        />
        <mat-autocomplete #auto="matAutocomplete">
          @for (option of filteredTimezoneOptions; track option) {
            <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <td>Router tracing</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.routerTracing()"
        (change)="settings.updateRouterTracing($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td>Log Build info</td>
    <td>
      <mat-slide-toggle
        [checked]="settings.buildInformationLog()"
        (change)="settings.updateBuildInformationLog($event.checked)"
      ></mat-slide-toggle>
    </td>
  </tr>
</table>
