import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DevSettingStore } from './dev-settings.store';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cca-dev-settings',
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  templateUrl: './dev-settings.component.html',
  styleUrl: './dev-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevSettingsComponent {
  settings = inject(DevSettingStore);

  timezones = Intl.supportedValuesOf('timeZone') as string[];
  timezoneControl = new FormControl<string | null>(this.settings.defaultZone());
  filteredTimezoneOptions: string[];

  constructor() {
    this.filteredTimezoneOptions = this.timezones.slice();

    this.timezoneControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((timezone) => {
        if (timezone && this.timezones.includes(timezone)) {
          if (this.settings.defaultZone() !== timezone) {
            this.settings.updateTimezone(timezone);
          }
        } else if (this.settings.defaultZone() !== null) {
          this.settings.updateTimezone(null);
        }
      });
  }

  filter(filterValue: string | null): void {
    if (filterValue) {
      this.filteredTimezoneOptions = this.timezones
        .slice()
        .filter((o) => o.toLowerCase().includes(filterValue.toLowerCase()));
    } else {
      this.filteredTimezoneOptions = this.timezones.slice();
    }
  }
}
